.leaflet-touch .leaflet-control-layers-toggle {
    background-image: url(./../assets/map/layer-icon.svg) !important;
  //   background-color: green;
  width: 44px;
  height: 44px;
  // background-color: var(--clr-secondary);
  // -webkit-mask-image: url(./../assets/map/layer-icon.svg);
  // mask-image: url(./../assets/map/layer-icon.svg);
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(./../assets/map/layer-icon.svg) !important;
  //   background-color: green;
    // background-size: 26px 26px;

  background-size: 26px 26px !important;
  // background-color: var(--clr-secondary);
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none !important;
    background-clip: padding-box;
}

.leaflet-control-layers {
    background: none !important; 
}

.leaflet-control-layers-overlays input[type=checkbox] + span {
    color: white !important;
}
