@keyframes dropTab {
  0% {
    top: -80px;
  }
  100% {
    top: 14px;
  }
}

@keyframes dropTitle {
  0% {
    top: -0px;
  }
  100% {
    top: 87px;
  }
}
